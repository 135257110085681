<template>
  <div class="amount-count">
    <div class="title-area">
      <div class="title">订单统计（线上订单）</div>
      <i :class="['el-icon-date','g-point',showDataPicker?'g-green':'']" @click="showDataPicker=!showDataPicker"></i>
    </div>
    <div class="date-time-picker" v-show="showDataPicker">
      <el-date-picker
        style="width:100%"
        @change="handleDateChange"
        value-format="yyyy-MM-dd HH:mm:ss"
        size="mini"
        v-model="dateRange"
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期">
      </el-date-picker>
    </div>
    <div>
      <el-card shadow="hover" class="statistic-card">
        <el-statistic :precision="2" :value="info.money*1" title="成交金额"></el-statistic>
      </el-card>
      <el-card shadow="hover" class="statistic-card">
        <el-statistic group-separator="," :value="info.num*1" title="成交笔数"></el-statistic>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'amountCount',
  props: ['begin', 'end'],
  data () {
    return {
      info: [],
      dateRange: [],
      showDataPicker: false
    }
  },
  methods: {
    handleDateChange (v) {
      if (v !== null) {
        this.getData(v[0], v[1])
      }
    },
    getData (begin, end) {
      const that = this
      that.axios.get('statement/amountCount?begin=' + begin + '&end=' + end).then(function (resp) {
        if (resp.data.code === 0) {
          that.info = resp.data.amountCount[0]
        }
        if (resp.data.code === 1) {
          that.$message.error(resp.data.msg)
        }
      })
    },
    initDateRange () {
      const d = new Date()
      const year = d.getFullYear()
      let month = d.getMonth()
      month = month + 1
      const lastDay = new Date(year, month, 0).getDate()
      month = (month > 9) ? month : ('0' + month)
      const begin = year + '-' + month + '-01 00:00:00'
      const end = year + '-' + month + '-' + lastDay + ' 23:59:59'
      this.dateRange = [begin, end]
    }
  },
  mounted () {
    if (this.begin !== undefined && this.end !== undefined) {
      this.dateRange = [this.begin, this.end]
    } else {
      this.initDateRange()
    }
    this.getData(this.dateRange[0], this.dateRange[1])
  }
}
</script>

<style scoped>
.title-area{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.title{
  font-size: 15px;
  color: #303133;
}
.date-time-picker{
  margin-bottom: 10px;
}
.statistic-area{
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.statistic-card{
  margin-bottom: 15px;
}
</style>
