<template>
  <div class="not-found">
    <div>∑(っ°Д°;)っ卧槽，页面不见了 !</div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  data () {
    return {}
  }
}
</script>

<style scoped>
.not-found{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
}
</style>
