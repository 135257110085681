<template>
  <div class="message-list">
    <div class="lay-tabs">
      <el-tabs v-model="activeName">
        <el-tab-pane label="待办提醒" name="timer"></el-tab-pane>
        <el-tab-pane label="物流消息" name="logistics"></el-tab-pane>
      </el-tabs>
    </div>
    <div class="message-area" v-show="activeName=='timer'">
      <todolist></todolist>
    </div>
    <div class="message-area" v-show="activeName=='logistics'">
      <LogisticsMessageList />
    </div>
  </div>
</template>

<script>
import Todolist from '@/components/ToDo/ToDoList.vue'
import LogisticsMessageList from '@/components/message/LogisticsMessageList.vue'
export default {
  name: 'MessageList',
  components: {
    Todolist,
    LogisticsMessageList
  },
  data () {
    return {
      activeName: 'timer'
    }
  }
}
</script>

<style scoped>
.message-list{
  padding: 0 15px 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.message-area{
  height: 100%;
  overflow: hidden;
}
.message-item{
  box-sizing: border-box;
  padding: 10px 15px 15px;
  margin-bottom: 15px;
}
</style>
