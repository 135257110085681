<template>
  <div class="index">
    <div class="data-panel">
      <turnoverRank />
    </div>
    <div class="data-panel">
      <teamRank />
    </div>
    <div class="data-panel" style="width:300px">
      <amountCount />
    </div>
    <div class="data-panel" style="width:300px">
      <sale-self-data />
    </div>
  </div>
</template>

<script>
import turnoverRank from '@/components/dataBoard/turnoverRank.vue'
import teamRank from '@/components/dataBoard/teamRank.vue'
import amountCount from '@/components/dataBoard/amountCount.vue'
import SaleSelfData from '@/components/dataBoard/SaleSelfData.vue'
export default {
  name: 'IndexView',
  components: {
    turnoverRank,
    teamRank,
    amountCount,
    SaleSelfData
  }
}
</script>
<style scoped>
.index{
  height: 100%;
  overflow: hidden;
  display: flex;
  box-sizing: border-box;
  padding: 15px;
}

.data-panel{
  padding: 15px;
  background-color: white;
  overflow-y: auto;
  margin-right: 15px;
}
</style>
