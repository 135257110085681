<template>
  <div class="add-item">
    <el-button size="small" type="text" @click="dialogFormVisible = true">+ 新增待办</el-button>
    <el-dialog title="新增待办事项" :visible.sync="dialogFormVisible" append-to-body width="380px" @close="dialogClose('form')">
      <el-form :model="form" size="small" label-position="top" :rules="rules" ref="form" >
        <el-form-item label="事项名称" prop="title">
          <el-input v-model="form.title" autocomplete="off" clearable maxlength="50"></el-input>
        </el-form-item>
        <el-form-item label="事项备注" prop="note">
          <el-input type="textarea" :autosize="{ minRows: 2}" placeholder="请输入内容" v-model="form.note" maxlength="200"></el-input>
        </el-form-item>
        <el-form-item label="提醒时间（选填）" prop="timer">
          <el-date-picker v-model="form.timer" type="datetime" placeholder="选择提醒日期时间，不选则不提醒" style="width:100%"
            format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm:00" default-time="10:00:00" :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="submitData('form')" size="small" :loading="posting">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogFormVisible: false,
      form: {
        title: '',
        note: '',
        timer: ''
      },
      posting: false,
      rules: {
        title: [
          { required: true, message: '请输入事项名称', trigger: 'blur' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' }
        ],
        note: [
          { min: 1, max: 200, message: '长度在 1 到 200 个字符', trigger: 'blur' }
        ]
      },
      pickerOptions: {
        shortcuts: [{
          text: '半小时后',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() + 3600 * 1000 * 0.5)
            picker.$emit('pick', date)
          }
        }, {
          text: '一小时后',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() + 3600 * 1000 * 1)
            picker.$emit('pick', date)
          }
        }, {
          text: '明天',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() + 3600 * 1000 * 24)
            picker.$emit('pick', date)
          }
        }, {
          text: '一周后',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() + 3600 * 1000 * 24 * 7)
            picker.$emit('pick', date)
          }
        }]
      }
    }
  },
  methods: {
    createTodo () {
      this.posting = true
      const that = this
      that.axios.post('todo', this.form).then(function (response) {
        const resp = response.data
        if (resp.error === 0) {
          that.$message.success('操作成功')
          that.$emit('success', resp.info)
          that.dialogFormVisible = false
        }
        that.posting = false
      })
    },
    submitData (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.createTodo()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    dialogClose (formName) {
      this.posting = false
      this.$refs[formName].resetFields()
    }
  }
}
</script>

<style scoped>
.add-item {
  display: inline-block;
}
</style>
