<template>
  <div class="amount-count">
    <div class="title-area">
      <div class="title">我的</div>
      <i :class="['el-icon-date','g-point',showDataPicker?'g-green':'']" @click="showDataPicker=!showDataPicker"></i>
    </div>
    <div class="date-time-picker" v-show="showDataPicker">
      <el-date-picker
        style="width:100%"
        @change="handleDateChange"
        value-format="yyyy-MM-dd HH:mm:ss"
        size="mini"
        v-model="dateRange"
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期">
      </el-date-picker>
    </div>
    <div>
      <el-descriptions :column="1" border v-loading="loading">
        <el-descriptions-item label="成交金额">￥{{info.order_money == null ? 0 : info.order_money}}</el-descriptions-item>
        <el-descriptions-item label="成交订单数">{{info.order_num}} 单</el-descriptions-item>
        <el-descriptions-item label="外呼数">{{info.call_num}} 通</el-descriptions-item>
        <el-descriptions-item label="通话时间长">{{(info.call_time/60).toFixed(0)}} 分钟</el-descriptions-item>
        <el-descriptions-item label="客资标记数">{{info.leads_tag_num}} 个</el-descriptions-item>
        <el-descriptions-item label="新增20数">{{info.tag_20}} 个</el-descriptions-item>
        <el-descriptions-item label="新增30数">{{info.tag_30}} 个</el-descriptions-item>
        <el-descriptions-item label="新增40数">{{info.tag_40}} 个</el-descriptions-item>
        <el-descriptions-item label="微信新增好友数">{{info.wechat_friend_increase}} 个</el-descriptions-item>
      </el-descriptions>
      <el-descriptions :column="1" style="margin-top:30px">
        <el-descriptions-item label="今日微信新增好友数">{{wechat_num_today==null?'_':wechat_num_today}} 个</el-descriptions-item>
      </el-descriptions>
      <div class="my-form">
        <el-input v-model="wechatNum" placeholder="今日新增微信好友数" clearable size="mini" style="margin-right:15px" @change="onSubmit"></el-input>
        <el-button type="primary" plain @click="onSubmit" size="mini" :disabled="wechatNum === null || wechatNum ==='' || posting">更新</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'amountCount',
  props: ['begin', 'end'],
  data () {
    return {
      loading: false,
      wechatNum: null,
      wechat_num_today: 0,
      info: {},
      dateRange: [],
      showDataPicker: false,
      posting: false
    }
  },
  methods: {
    handleDateChange (v) {
      if (v !== null) {
        this.getData(v[0], v[1])
      }
    },
    getData (begin, end) {
      const that = this
      that.loading = true
      that.axios.get('statement/crm?begin=' + begin + '&end=' + end).then(function (resp) {
        if (resp.data.code === 0) {
          that.info = resp.data.crmData
        }
        if (resp.data.code === 1) {
          that.$message.error(resp.data.msg)
        }
        that.loading = false
      })
    },
    initDateRange () {
      const d = new Date()
      const year = d.getFullYear()
      let month = d.getMonth()
      month = month + 1
      const lastDay = new Date(year, month, 0).getDate()
      month = (month > 9) ? month : ('0' + month)
      const begin = year + '-' + month + '-01 00:00:00'
      const end = year + '-' + month + '-' + lastDay + ' 23:59:59'
      this.dateRange = [begin, end]
    },
    onSubmit () {
      const that = this
      that.posting = true
      if (that.wechatNum !== '' || that.wechatNum !== null) {
        that.axios.post('crm/data/sale/wechat_friend_increase', { num: that.wechatNum }).then(function (resp) {
          if (resp.data.error === 0) {
            that.$message.success('更新成功')
            that.wechat_num_today = that.wechatNum
            that.wechatNum = null
          }
          that.posting = false
        })
      }
    },
    getWechatNumToday () {
      const that = this
      that.axios.get('crm/data/sale/wechat_friend_increase/1').then(function (resp) {
        that.wechat_num_today = resp.data.num
      })
    }
  },
  mounted () {
    if (this.begin !== undefined && this.end !== undefined) {
      this.dateRange = [this.begin, this.end]
    } else {
      this.initDateRange()
    }
    this.getData(this.dateRange[0], this.dateRange[1])
    this.getWechatNumToday()
  }
}
</script>

<style scoped>
.title-area{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.title{
  font-size: 15px;
  color: #303133;
}
.date-time-picker{
  margin-bottom: 10px;
}
.my-form{
  display: flex;
}
</style>
