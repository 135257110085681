<template>
  <div class="todo-list">
    <div class="todo-header">
      <div>
        <el-popconfirm title="永久删除已选的事项，确定吗？" v-show="selectedItems.length > 0" @confirm="deleteSelected">
          <el-button slot="reference" size="mini" type="danger" plain>删除</el-button>
        </el-popconfirm>
      </div>
      <div>
        <add-item @success="addItemSuccess" />
        <el-divider direction="vertical"></el-divider>
        <el-select v-model="todo_status" placeholder="请选择" size="mini" style="width: 100px" @change="handleStatusChange">
          <el-option label="全部" value=""></el-option>
          <el-option label="待开始" value="w"></el-option>
          <el-option label="进行中" value="i"></el-option>
          <el-option label="已完成" value="d"></el-option>
        </el-select>
        <el-divider direction="vertical"></el-divider>
        <el-button size="mini" type="default" plain icon="el-icon-refresh" :loading="tableLoading" @click="getList">刷新</el-button>
      </div>
    </div>
    <el-table :data="tableData" style="width: 100%" height="100%" @selection-change="handleSelectionChange" v-loading="tableLoading">
      <el-table-column type="selection" width="50" align="center"></el-table-column>
      <el-table-column type="expand" label="展开">
        <template slot-scope="scope">
          <div class="todo-content">
            <div>{{ scope.row.note }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="代办事项" prop="title" show-overflow-tooltip min-width="100px"></el-table-column>
      <el-table-column label="提醒时间" prop="timer" width="140px" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="timerClick(scope.row)">
            <i class="el-icon-time" style="margin-right:5px"></i>{{ scope.row.timer? scope.row.timer : '设置提醒时间' }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="状态" prop="status" width="100" align="center">
        <template slot-scope="scope">
          <el-popover placement="bottom" title="切换状态" width="230" trigger="hover">
            <div class="status-list">
              <el-button round size="mini" type="default" plain @click="updateStatus(scope.row.id,'w')" :disabled="scope.row.status === 'w'">待开始</el-button>
              <el-button round size="mini" type="warning" plain @click="updateStatus(scope.row.id,'i')" :disabled="scope.row.status === 'i'">进行中</el-button>
              <el-button round size="mini" type="success" plain @click="updateStatus(scope.row.id,'d')" :disabled="scope.row.status === 'd'">已完成</el-button>
            </div>
            <el-button round size="mini" type="default" plain slot="reference" v-if="scope.row.status ==='w'">待开始</el-button>
            <el-button round size="mini" type="warning" plain slot="reference" v-if="scope.row.status ==='i'">进行中</el-button>
            <el-button round size="mini" type="success" plain slot="reference" v-if="scope.row.status ==='d'">已完成</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <div class="g-pagination-wrap">
      <el-pagination background @current-change="getList" :current-page.sync="page" :page-size.sync="pageSize" layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
    </div>
    <el-dialog :title="activeRow.title" :visible.sync="dialogTimer" width="350px" append-to-body :show-close="false">
      <el-form :model="timerForm" ref="timerForm" size="mini">
        <el-form-item label="提醒开关">
          <el-switch v-model="timerForm.status" active-color="#13ce66" @change="timerForm.timer = null"></el-switch>
          <span v-if="timerForm.status"> 提醒已开启</span>
          <span v-else> 提醒已关闭</span>
        </el-form-item>
        <el-form-item label="提醒时间" v-if="timerForm.status">
          <el-date-picker v-model="timerForm.timer" type="datetime" placeholder="选择提醒日期时间" style="width:100%" value-format="yyyy-MM-dd HH:mm:00" format="yyyy-MM-dd HH:mm" default-time="10:00:00" :clearable="false"></el-date-picker>
          <el-button type="text" @click="setTimer('a')">半小时后</el-button>
          <el-button type="text" @click="setTimer('b')">1小时后</el-button>
          <el-button type="text" @click="setTimer('c')">明天</el-button>
          <el-button type="text" @click="setTimer('d')">一周后</el-button>
          <el-button type="text" @click="timerForm.timer = ''">自定义</el-button>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="dialogTimer = false" size="mini">取 消</el-button>
        <el-button @click="updateTimer(timerForm.id,timerForm.timer)" size="mini" type="primary" :disabled="timerForm.timer === activeRow.timer || timerForm.timer == ''">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import AddItem from '@/components/ToDo/AddItem'
export default {
  name: 'ToDoList',
  components: {
    AddItem
  },
  data () {
    return {
      page: 1,
      pageSize: 15,
      total: 0,
      selectedItems: [],
      todo_status: '',
      tableData: [],
      tableLoading: false,
      dialogTimer: false,
      timerForm: {
        id: null,
        timer: null,
        status: false
      },
      activeRow: {},
      updating: false
    }
  },
  methods: {
    addItemSuccess (e) {
      this.tableData.unshift(e)
    },
    updateStatus (id, status) {
      const that = this
      that.axios.put('todo/' + id, { status }).then(function (res) {
        if (res.data.error === 0) {
          that.$message.success('操作成功')
          for (let i = 0; i < that.tableData.length; i++) {
            if (that.tableData[i].id * 1 === id * 1) {
              that.tableData[i].status = status
            }
          }
        }
      })
    },
    updateTimer (id, timer) {
      const that = this
      that.updating = true
      that.axios.put('todo/' + id, { timer }).then(function (res) {
        if (res.data.error === 0) {
          that.$message.success('操作成功')
          that.dialogTimer = false
          for (let i = 0; i < that.tableData.length; i++) {
            if (that.tableData[i].id * 1 === id * 1) {
              if (timer !== null) {
                that.tableData[i].timer = timer.slice(0, -3)
              } else {
                that.tableData[i].timer = null
              }
            }
          }
          that.updating = false
        }
      })
    },
    timerClick (row) {
      this.timerForm.id = row.id
      this.timerForm.timer = row.timer + ':00'
      this.timerForm.status = row.timer !== null
      this.activeRow = row
      this.dialogTimer = true
    },
    setTimer (val) {
      let timer = null
      // 当期时间戳
      const now = new Date().getTime()
      switch (val) {
        case 'a': // 半小时之后
          timer = new Date(now + 30 * 60 * 1000)
          break
        case 'b': // 一小时之后
          timer = new Date(now + 60 * 60 * 1000)
          break
        case 'c': // 明天这个时间
          timer = new Date(now + 24 * 60 * 60 * 1000)
          break
        case 'd': // 一周之后
          timer = new Date(now + 7 * 24 * 60 * 60 * 1000)
          break
      }
      timer = timer.toLocaleString()
      // 将时间最后两位置为00
      timer = timer.slice(0, -2) + '00'
      timer = timer.replaceAll('/', '-')
      this.timerForm.timer = timer.slice(0, -2) + '00'
    },
    handleSelectionChange (val) {
      this.selectedItems = val
    },
    handleStatusChange () {
      this.page = 1
      this.getList()
    },
    getList () {
      const that = this
      that.tableLoading = true
      that.axios.get('todo', {
        params: {
          page: that.page,
          pageSize: that.pageSize,
          status: that.todo_status
        }
      }).then(function (res) {
        const resp = res.data
        that.total = resp.total
        that.tableData = resp.list
        that.tableLoading = false
      })
    },
    deleteSelected () {
      const ids = []
      for (let i = 0; i < this.selectedItems.length; i++) {
        const item = this.selectedItems[i]
        ids.push(item.id)
      }
      const that = this
      that.axios.post('todo_delete', { ids }).then(function (resp) {
        if (resp.data.error === 0) {
          that.$message.success('删除成功')
          that.getList()
        }
        if (resp.data.error === 1) {
          that.$message.error('发生错误，请联系管理员')
        }
      })
    }
  },
  mounted () {
    this.getList()
  }
}
</script>

<style scoped>
.todo-list{
  background-color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.todo-header{
  display: flex;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.03);
  padding: 5px 15px;
}
.todo-content{
  padding: 10px 15px;
}
.status-list {
  width: 230px;
  display: flex;
  justify-content: space-between;
}
</style>
