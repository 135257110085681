<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'app'
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type="number"]{
    -moz-appearance: textfield;
}
html,body,#app{
  width: 100%;
  height: 100%;
  overflow: auto;
  margin: 0;
  padding: 0;
}
.g-point{
  cursor: pointer;
}
.g-can-click{
  color: #409EFF;
  cursor: pointer;
}
.g-pagination-wrap{
  padding: 15px 0 0;
  text-align: center;
}
.g-drawer-main{
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  background-color: #f5f7fa;
}
.g-drawer-wrap{
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.g-red{
  color:#F56C6C;
}
.g-yellow{
  color: #E6A23C;
}
.g-green{
  color: #67C23A;
}
.g-nothingness{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
}
</style>
