<template>
  <div class="logistics-message-list">
    <div class="list-header">
      <div>
      </div>
      <div>
        <el-button size="mini" type="default" plain icon="el-icon-refresh" :loading="tableLoading" @click="getList">刷新</el-button>
      </div>
    </div>
    <el-table :data="tableData" style="width: 100%" height="100%" size="mini">
      <el-table-column type="expand" label="展开">
        <template slot-scope="scope">
          <div class="msg-content">
            <el-descriptions size="mini" :column="1">
              <el-descriptions-item label="单位名称">{{scope.row.client}}</el-descriptions-item>
              <el-descriptions-item label="供应链订单号">{{scope.row.order_id}}</el-descriptions-item>
              <el-descriptions-item label="物流公司">{{scope.row.company}}</el-descriptions-item>
              <el-descriptions-item label="物流单号">{{scope.row.tracking_number}}</el-descriptions-item>
              <el-descriptions-item label="物流备注">{{scope.row.remarks}}</el-descriptions-item>
          </el-descriptions>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="物流消息" show-overflow-tooltip min-width="100px">
        <template slot-scope="scope">
          <span>【{{scope.row.client}}】的订单有新的物流动态</span>
        </template>
      </el-table-column>
      <el-table-column label="时间" prop="create_time" width="140px" show-overflow-tooltip></el-table-column>
    </el-table>
    <div class="g-pagination-wrap">
      <el-pagination background @current-change="getList" :current-page.sync="page" :page-size.sync="pageSize" layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LogisticsMessageList',
  data () {
    return {
      page: 1,
      pageSize: 20,
      total: 0,
      tableData: [],
      tableLoading: false
    }
  },
  methods: {
    getList () {
      const that = this
      that.tableLoading = true
      that.axios.get('scm/express', {
        params: {
          page: that.page,
          pageSize: that.pageSize
        }
      }).then(function (res) {
        const resp = res.data
        that.total = resp.total
        that.tableData = resp.list
        that.tableLoading = false
      })
    }
  },
  mounted () {
    this.getList()
  }
}
</script>

<style scoped>
.logistics-message-list{
  background-color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.list-header{
  display: flex;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.03);
  padding: 5px 15px;
}
.msg-content{
  padding: 10px 15px;
}
</style>
