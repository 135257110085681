<template>
  <div class="message-center">
    <el-badge is-dot class="item" :hidden="unreadMessage == 0" :value="unreadMessage">
      <el-button :type="online?'primary':'info'" icon="el-icon-bell" circle size="mini" @click="bellClick"></el-button>
    </el-badge>
    <el-drawer title="消息中心" :visible.sync="drawer" append-to-body>
      <message-list />
    </el-drawer>
  </div>
</template>

<script>
import confetti from 'canvas-confetti'
import MessageList from '@/components/message/MessageList.vue'
export default {
  name: 'MessageNotice',
  components: {
    MessageList
  },
  data () {
    return {
      drawer: false,
      socket: null,
      intervalTimeId: null,
      unreadMessage: 0,
      online: false,
      visibilityState: true
    }
  },
  methods: {
    handleVisibilityChange () {
      this.visibilityState = document.visibilityState
      if (this.visibilityState === 'visible' && this.online === false) {
        this.initSocket()
      }
    },
    bellClick () {
      this.drawer = true
      if (!this.online) {
        this.initSocket()
      }
    },
    initSocket () {
      const that = this
      if (!!window.WebSocket && window.WebSocket.prototype.send) {
        console.log('您的浏览器支持Websocket通信协议')
        if (this.intervalTimeId !== null) {
          clearInterval(this.intervalTimeId)
        }
        this.socket = new WebSocket('wss://wss.micheng.cn/')
        this.socket.onopen = () => {
          console.log('连接建立成功')
          that.online = true
          that.bindClientID()
          that.heartBeat()
        }
        this.socket.onmessage = (event) => {
          console.log('收到服务器消息：' + event.data)
          let data = ''
          try {
            data = JSON.parse(event.data)
          } catch (error) {
            data = event.data
          }
          switch (data.type) {
            case 'newOrder':
              showConfetti()
              that.$message({
                message: data.title,
                type: 'success',
                showClose: true
              })
              break

            case 'express':
              that.$notify({
                title: '发货提醒',
                message: data.title,
                showClose: true,
                duration: 0
              })
              break

            case 'todo':
              that.$notify({
                title: '待办提醒',
                message: data.title,
                showClose: true,
                duration: 0
              })
              break
          }
        }
        this.socket.onclose = () => {
          console.log('连接关闭')
          // 取消定时任务
          clearInterval(that.intervalTimeId)
          that.online = false
        }
        this.socket.onerror = () => {
          console.log('连接发生错误')
          clearInterval(this.intervalTimeId)
          that.online = false
        }
      } else {
        this.$message.warning('您的浏览器不支持Websocket通信协议, 请使用Chrome或者Firefox浏览器!')
      }
    },
    heartBeat () {
      const that = this
      this.intervalTimeId = setInterval(() => {
        that.socket.send('')
        const d = new Date()
        console.log('heartBeat', d.toLocaleString())
      }, 50000)
    },
    bindClientID () {
      // 绑定客户端ID
      const token = sessionStorage.getItem('token')
      const msg = {
        type: 'login',
        token: token
      }
      this.socket.send(JSON.stringify(msg))
    }
  },
  beforeDestroy () {
    // 关闭 WebSocket 连接
    if (this.socket) {
      this.socket.close()
    }
  },
  mounted () {
    this.initSocket()
    document.addEventListener('visibilitychange', this.handleVisibilityChange)
  }
}
function showConfetti () {
  const count = 200
  confetti({
    spread: 26,
    origin: { y: 0.7 },
    particleCount: Math.floor(count * 0.25)
  })
  confetti({
    spread: 60,
    origin: { y: 0.7 },
    particleCount: Math.floor(count * 0.22)
  })
  confetti({
    spread: 100,
    origin: { y: 0.7 },
    decay: 0.91,
    scalar: 0.8,
    particleCount: Math.floor(count * 0.35)
  })
  confetti({
    spread: 120,
    startVelocity: 25,
    decay: 0.92,
    scalar: 1.2,
    origin: { y: 0.7 },
    particleCount: Math.floor(count * 0.1)
  })
  confetti({
    spread: 120,
    startVelocity: 45,
    origin: { y: 0.7 },
    particleCount: Math.floor(count * 0.1)
  })
}
</script>
<style scoped>
.message-center{
  display: inline-block;
}
</style>
