<template>
  <div class="login">
    <div class="login-area">
      <!-- <div class="brand">米城优采</div> -->
      <div class="logo-area">
        <img class="logo" src="../assets/images/logo2.png">
      </div>
      <div class="slogan">让小微企业与政采连接</div>
      <el-form :model="loginForm" ref="loginForm" @submit.native.prevent>
        <div class="login-wrap">
          <div class="input-item">
            <el-form-item prop="account" :error="error.account">
              <el-input v-model="loginForm.account" size="small" placeholder="账号" maxlength="15" @focus="error.account = ''"></el-input>
            </el-form-item>
          </div>
          <div  class="input-item">
            <el-form-item prop="password" :error="error.password">
              <el-input v-model="loginForm.password" size="small" show-password placeholder="密码" @focus="error.password = ''"></el-input>
            </el-form-item>
          </div>
          <el-form-item>
            <el-button native-type="submit" type="primary" size="small" plain @click="login">立即登录</el-button>
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'LoginView',
  data () {
    return {
      loginForm: {
        account: '',
        password: ''
      },
      error: {
        account: '',
        password: ''
      }
    }
  },
  methods: {
    login () {
      const that = this
      const account = this.loginForm.account.trim()
      const password = this.loginForm.password.trim()
      this.error.account = account.length > 0 ? '' : '用户名不能为空'
      this.error.password = password.length > 0 ? '' : '密码不能为空'
      if (this.error.account === '' && this.error.password === '') {
        this.axios.post('/login', that.loginForm).then(
          function (response) {
            if (response.data.code === 0) {
              sessionStorage.setItem('token', response.data.token)
              const staff = JSON.stringify(response.data.staff)
              sessionStorage.setItem('staff', staff)
              that.$store.commit('setStaff', { staff: response.data.staff })
              that.$router.push({ name: 'index' })
            } else {
              that.$message.error('发生错误，登录失败！')
            }
          }
        ).catch(function (error) {
          console.log(error)
          that.$notify.error({
            title: '错误',
            message: '系统发生错误，请联系管理员',
            duration: 0
          })
        })
      }
    }
  }
}
</script>
<style scoped>
.login{
  height: 100%;
  width: 100%;
  background-image: url('../assets/images/cool-background.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
}
.login-area{
  min-width: 500px;
  box-sizing: border-box;
  padding: 80px;
}
.login-wrap{
  width: 250px;
  padding-top: 30px;
}
.logo{
  width: 250px;
}
.brand{
  font-size: 50px;
  font-weight: 1000;
  letter-spacing: 20px;
}
.slogan{
  width: 250px;
  text-align: right;
  font-size: 15px;
  padding: 10px 0 0;
  color: #303133;
}
.input-item{
  margin-bottom: 20px;
}
</style>
