<template>
  <el-submenu :index="menu.id+'m'" v-if="menu.type === 1" :disabled="menu.disabled?true:false" :popper-append-to-body="false" >
    <template slot="title">{{menu.name}}</template>
    <NavMenu :menu="item" v-for="item in menu.children" :key="item.id"/>
  </el-submenu>
  <el-menu-item :index="menu.path" v-else :disabled="menu.disabled?true:false">{{menu.name}}</el-menu-item>
</template>

<script>
import NavMenu from '@/components/base/NavMenu.vue'
export default {
  name: 'NavMenu',
  props: {
    menu: Object
  },
  components: {
    NavMenu
  }
}
</script>

<style scoped>

</style>
