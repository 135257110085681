<template>
  <div class="base">
    <div class="nav">
      <el-menu :default-active="$route.path" mode="horizontal" router>
        <el-menu-item index="0">
          <img class="logo" src="../assets/images/logo2.png">
        </el-menu-item>
        <selfMenu :menu="menu" v-for="menu in navMenu" :key="menu.id" />
        <div class="nav-right">
          <el-button size="mini" type="success" icon="el-icon-phone" circle @click="drawer.phoneDial = true"></el-button>
          <el-divider direction="vertical"></el-divider>
          <message-center />
          <el-divider direction="vertical"></el-divider>
          <el-dropdown>
            <div class="staff-info">
              <div class="staff-name">{{staff.name}} </div>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item icon="el-icon-setting" @click.native="$router.push('/my/setting')">设置</el-dropdown-item>
              <el-dropdown-item icon="el-icon-box" @click.native="$router.push('/tools')">工具箱</el-dropdown-item>
              <el-dropdown-item icon="el-icon-switch-button"  divided @click.native="handleExit">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-menu>
    </div>
    <div class="content">
      <router-view></router-view>
    </div>
    <el-drawer title="拨打电话" :visible.sync="drawer.phoneDial" direction="rtl" size="300px" :wrapperClosable="false">
      <phone-dial :caller="staff.work_phone" />
    </el-drawer>
  </div>
</template>

<script>
import selfMenu from '@/components/base/NavMenu.vue'
import PhoneDial from '@/components/crm/PhoneDial.vue'
import MessageCenter from '@/components/message/MessageCenter.vue'
export default {
  name: 'BaseView',
  components: {
    selfMenu,
    PhoneDial,
    MessageCenter
  },
  computed: {
    staff () {
      return this.$store.state.staff
    }
  },
  data () {
    return {
      logo: '../assets/logo.png',
      navMenu: [],
      drawer: {
        phoneDial: false
      }
    }
  },
  methods: {
    getStaffInfo () {
      let staff = sessionStorage.getItem('staff')
      staff = JSON.parse(staff)
      this.$store.commit('setStaff', { staff })
    },
    handleExit () {
      sessionStorage.removeItem('token')
      sessionStorage.removeItem('staff')
      this.$router.push('login')
    },
    getNavMenu () {
      const that = this
      that.axios.get('user/menus').then(function (resp) {
        if (resp.data.code === 0) {
          that.navMenu = that.getChildren(resp.data.menus, 0)
        }
      })
    },
    getChildren (menus, parentId = 0) {
      const list = []
      for (let i = 0; i < menus.length; i++) {
        const m = menus[i]
        if (m.parent_id === parentId) {
          if (m.type * 1 === 1) {
            const children = this.getChildren(menus, m.id)
            m.children = children
          }
          list.push(m)
        }
      }
      return list
    }
  },
  mounted () {
    this.getStaffInfo()
    this.getNavMenu()
  }
}
</script>
<style scoped>
.base{
  height: 100%;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  background-color: #f1f1f1;
}
.nav{
  position: sticky;
  top: 0;
  z-index: 100;
}
.nav-right{
  position: absolute;
  right: 20px;
  top: 0;
  bottom: 0;
  height: 100%;
  display: flex;
  align-items:center;
}
.logo{
  max-width: 200px;
  max-height: 50%;
}
.staff-info{
  display: flex;
  align-items: center;
}
.staff-name{
  padding: 1px 15px;
  font-size: 15px;
  color: #303133;
  cursor: pointer;
}
.content{
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 1;
  height: 100%;
  overflow-y: auto;
}
</style>
