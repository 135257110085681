<template>
  <div class="phone-dial">
    <div class="phone-number-area">
      <div class="input-area">
        <el-input v-model.number="tel" placeholder="请输入电话号码" size="normal" clearable :maxlength="11" autofocus></el-input>
      </div>
      <div class="number-row">
        <div class="number" @click="appendNumber(1)">1</div>
        <div class="number" @click="appendNumber(2)">2</div>
        <div class="number" @click="appendNumber(3)">3</div>
      </div>
      <div class="number-row">
        <div class="number" @click="appendNumber(4)">4</div>
        <div class="number" @click="appendNumber(5)">5</div>
        <div class="number" @click="appendNumber(6)">6</div>
      </div>
      <div class="number-row">
        <div class="number" @click="appendNumber(7)">7</div>
        <div class="number" @click="appendNumber(8)">8</div>
        <div class="number" @click="appendNumber(9)">9</div>
      </div>
      <div class="number-row">
        <div class="number" @click="appendNumber(0)">0</div>
      </div>
      <div class="dial-btn-area" @click="dial(item)" v-for="item in staff.work_phone" :key="item">
        用 {{item}} 拨号
      </div>
      <div class="dial-btn-area-error" v-if="staff.work_phone.length < 1">
        请先到'设置'中设置电话号码
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tel: ''
    }
  },
  computed: {
    staff () {
      return this.$store.state.staff
    }
  },
  methods: {
    appendNumber (x) {
      this.tel = this.tel + x
    },
    dial (caller) {
      if (this.tel.length < 3) {
        this.$message.error('电话号码似乎不正确')
      } else {
        const that = this
        that.axios.post('crm/dial', { caller, callee: that.tel }).then(function (resp) {
          if (resp.data.code === 0) {
            that.$message.success('正在呼叫')
          }
          if (resp.data.code === 1) {
            that.$message.error(resp.data.msg)
          }
        })
      }
    }
  }
}
</script>

<style scoped>
.phone-number-area{
  width: 200px;
  margin: 0 auto;
}
.input-area{
  margin-bottom: 20px;
}
.number-row{
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}
.number{
  background-color: #cacaca;
  color: black;
  font-size: 27px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dial-btn-area{
  background-color: #67C23A;
  color: white;
  font-size: 17px;
  font-weight: 300;
  height: 40px;
  line-height: 40px;
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  margin-bottom: 15px;
}
.dial-btn-area-error{
  background-color: red;
  color: white;
  font-size: 14px;
  font-weight: 400;
  height: 40px;
  line-height: 40px;
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  margin-bottom: 15px;
}
</style>
