<template>
  <div class="turnover-rank">
    <div class="title-area">
      <div class="title">订单排行榜（线上订单）</div>
      <i :class="['el-icon-date','g-point',showDataPicker?'g-green':'']" @click="showDataPicker=!showDataPicker"></i>
    </div>
    <div class="date-time-picker" v-show="showDataPicker">
      <el-date-picker
        style="width:100%"
        @change="handleDateChange"
        value-format="yyyy-MM-dd HH:mm:ss"
        size="mini"
        v-model="dateRange"
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期">
      </el-date-picker>
    </div>
    <el-table :data="tableData" border stripe size="mini">
      <el-table-column prop="team_name" label="所属团队" width="70" align="center"></el-table-column>
      <el-table-column label="排名" width="50" align="center">
        <template slot-scope="scope">
          <img alt="champion" class="champion" src="@/assets/images/champion.png" v-if="scope.$index === 0">
          <span v-else>{{scope.$index+1}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="origin" label="来源" width="100px" show-overflow-tooltip>
        <template slot-scope="scope">
          <span :class="scope.row.origin === staff.name?'self':''">{{scope.row.origin}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="amount" label="成交金额" width="95px"></el-table-column>
      <el-table-column prop="profit" label="预估毛利" width="95px">
        <template slot-scope="scope">
          {{(scope.row.amount*0.25).toFixed(2)}}
        </template>
      </el-table-column>
      <el-table-column prop="num" label="成交单量"></el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: ['begin', 'end'],
  data () {
    return {
      tableData: [],
      dateRange: [],
      showDataPicker: false
    }
  },
  computed: {
    staff () {
      return this.$store.state.staff
    }
  },
  methods: {
    handleDateChange (v) {
      if (v !== null) {
        this.getData(v[0], v[1])
      }
    },
    getData (begin, end) {
      const that = this
      that.axios.get('statement/turnoverRank?begin=' + begin + '&end=' + end).then(function (resp) {
        if (resp.data.code === 0) {
          that.tableData = resp.data.turnoverRank
        }
        if (resp.data.code === 1) {
          that.$message.error(resp.data.msg)
        }
      })
    },
    initDateRange () {
      const d = new Date()
      const year = d.getFullYear()
      let month = d.getMonth()
      month = month + 1
      const lastDay = new Date(year, month, 0).getDate()
      month = (month > 9) ? month : ('0' + month)
      const begin = year + '-' + month + '-01 00:00:00'
      const end = year + '-' + month + '-' + lastDay + ' 23:59:59'
      this.dateRange = [begin, end]
    }
  },
  mounted () {
    if (this.begin !== undefined && this.end !== undefined) {
      this.dateRange = [this.begin, this.end]
    } else {
      this.initDateRange()
    }
    this.getData(this.dateRange[0], this.dateRange[1])
  }
}
</script>

<style scoped>
.title-area{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.title{
  font-size: 15px;
  color: #303133;
}
.date-time-picker{
  margin-bottom: 10px;
}
.champion{
  width: 70%;
}
.self{
  color: #409EFF;
  font-weight: bold;
}
</style>
